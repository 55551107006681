import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  changeFieldType,
  createField,
  deleteField,
  hideAllField,
  updateField,
} from "../../api/fieldApi";
import { getTable } from "../../api/tableApi";
import { insertRow, uploadImage, updateRow, deleteRow, insertMultipleRows, uploadCSV,getDeletedRows,restoreRow,getRowHistory } from "../../api/rowApi";
import {
  addOptionToColumn,
  setTableLoading,
  updateColumnType,
} from "./tableSlice";
import { runQueryonTable } from "../../api/filterApi";
import { createView, deleteFieldInView } from "../../api/viewApi";
// import { getTableInfo } from "./tableSelector";
import { getAllTableInfo } from "../allTable/allTableSelector";
import { setAllTablesData } from "../allTable/allTableSlice";
import {
  BulkAddColumnsTypes,
  DeleteColumnsTypes,
  FilterDataTypes,
  UpdateColumnHeaderTypes,
} from "../../types/tableDataTypes";
import { TableColumType, TableDataType } from "../../types/tableDataTypes";
import { getOrgUsers } from "../database/databaseSelector";

let loadingToast : any;

const getHeaders = async (
  dbId: string,
  tableName: string,
  payloadfields,
  { getState },
  fieldArrayInFilter = null
): Promise<TableColumType> => {
  let fields =
    payloadfields ||
    (await getAllTableInfo(getState())?.tables?.[tableName]?.fields);
  const fieldIds =
    fieldArrayInFilter ||
    (await getAllTableInfo(getState())?.tables?.[tableName]?.fieldIds);
  let columns = [] as any;
  // const viewFields  = getAllTableInfo(getState())?.tables?.[tableName]?.view?.fields;
  if (!fieldArrayInFilter) {
    fields = { ...fields };
  }
  let tables=await getAllTableInfo(getState())?.tables;
  fieldIds.forEach((fieldId, index) => {
    const field = fields[fieldId] ? {...fields[fieldId]} : undefined;
    if (field) {
      if(field?.metaData?.isLookup) {
          field.fieldType=tables?.[field?.tableId]?.['fields']?.[field?.actualFieldId]?.['fieldType'];  
        }
      var json = {
        title: "",
        id: "",
        dataType: "",
        hasMenu: true,
        // minWidth: 100,
        // options: [],
        metadata: {},
        width: field.metaData?.width ? field.metaData.width : 150,
        icon: "",
      };

      json.id = fieldId;
      json.title = field.fieldName?.toLowerCase() || fieldId.toLowerCase();
      if (field.tableId)  json["tableId"] = field.tableId
      if (field.actualFieldId)  json["actualFieldId"] = field.actualFieldId
      // json.accessor = fieldId.toLowerCase();
      json.metadata = field.metaData;
      json.dataType = field.fieldType?.toLowerCase();
      json.icon = field.fieldType?.toLowerCase();
      columns[index] = json; // Replace the existing object at the corresponding index
    }
  });

  let result = columns.filter((el) => el !== "empty");
  return result;
};

function showToast() {
  const toastId = toast.loading('Loading data...', {
      position: 'bottom-right',
      style: {
          width: '170px', 
          height: '50px',
          fontSize: '14px', 
          borderRadius: '8px',
          backgroundColor: '#323232', 
          color: '#fff',
          marginLeft: 'auto',
          marginRight: '0'
      }
  });
  return toastId;
}

function dismissToast() {
  toast.dismiss(loadingToast);
  loadingToast = undefined;
}

const getRowData = async (
  dbId: string,
  tableName: string,
  { getState },
  org_id?: string,
  page?: number
): Promise<TableDataType> => {
  const state = getState();
  const userJson = state.dataBase.currentOrgUsers;
  const dataAndPageNo = {
    offset: true,
    rows: [],
  } as any;
    const data = await getTable(dbId, tableName, page);
    const obj = data.data.data?.rows || data.data.data;
    obj.map((row) => {   // TODO : To be fixed later
      row["createdby"] = userJson?.[row["createdby"]]
        ? userJson?.[row["createdby"]]?.name
        : row["createdby"];
      row["updatedby"] = userJson?.[row["updatedby"]]
        ? userJson?.[row["updatedby"]]?.name : row["updatedby"];
    });
    dataAndPageNo.offset = data.data.data?.offset;
    dataAndPageNo.rows = [...dataAndPageNo.rows, ...obj];
    dataAndPageNo.pageNo = page;
    
  return dataAndPageNo;
};

export const addColumns = createAsyncThunk(
  "table/addColumns",
  async (payload: TableDataType, { dispatch }) => {
    dispatch(addOptionToColumn(payload));
    return 5;
  }
);

export const resetColumnHeaders = createAsyncThunk(
  "table/resetColumnHeaders", 
  async (payload : any, {getState} : any) => {
    let columns = await getHeaders(
      payload.dbId, 
      payload.tableId, 
      payload.fields, 
      {getState}  
    )
    return columns;
  }
)

export const bulkAddColumns = createAsyncThunk(
  "table/bulkAddColumns",
  async (payload: BulkAddColumnsTypes, { getState, dispatch } : {getState : Function, dispatch : Function}) => {
    loadingToast ||= showToast();
    let columns : any;
    columns = await getHeaders(
      payload.dbId,
      payload.tableName,
      payload?.fields,
      { getState }
    ); 
    const data = await getRowData(
      payload.dbId,
      payload.tableName,
      { getState },
      payload.org_id,
      payload.pageNo
    ).catch(err => {
      toast.dismiss();
      throw err;
    });

    const dataa = {
      columns: columns,
      row: data.rows,
      tableId: payload?.tableName,
      dbId: payload?.dbId,
      pageNo: data?.pageNo || 1,
      isMoreData: !(data?.offset == null),
      filterId: ""
    };
    dispatch(setTableLoading(false));
    let getMoreData = dataa.isMoreData && (dataa.pageNo === 1 || (getState().table.pageNo + 1 === dataa.pageNo && getState().table.tableId === payload.tableName));
    if(getMoreData) {
      dispatch(bulkAddColumns({
        dbId: payload?.dbId,
        tableName : payload?.tableName,
        pageNo: dataa.pageNo + 1
      }))
    }else{
      dismissToast()
    }

    return dataa;
  }
);

export const filterData = createAsyncThunk(
  "table/bulkAddColumns",
  async (payload: FilterDataTypes, { getState, dispatch }: {getState : Function, dispatch : Function}) => {
    loadingToast ||= showToast();
    const table = getAllTableInfo(getState())?.tables?.[payload?.tableId];
    const filter = table?.filters?.[payload?.filterId];
    let filterFields = filter?.fields;
    if (filterFields && Object.keys(filterFields).length == 0) {
      filterFields = table?.fields;
    }
    const fieldArrayInFilter = filter?.fieldIds;
    const state = getState();
    // const userInfo = allOrg(state);
    // const userJson = await replaceCreatedByIdWithName(
    //   userInfo,
    //   payload?.org_id
    // );
    const userJson = state.dataBase.currentOrgUsers;
    // const createdby = "fld" + payload?.tableId.substring(3) + "createdby";

    var page = payload?.pageNo || 1;
    const querydata = await runQueryonTable(
      payload.dbId,
      payload?.tableId,
      payload?.filterId,
      page
    ).catch(error => {
      dismissToast();
      throw error;
    });
    querydata?.data?.data?.rows &&
      querydata?.data?.data?.rows?.map((row) => {
        row["createdby"] = userJson?.[row["createdby"]]
          ? userJson?.[row["createdby"]]?.name : row["createdby"];
      });
    var rows = querydata?.data?.data?.rows || [];
    let offset = !!querydata?.data?.data?.offset;
    
    let columns = {};
    fieldArrayInFilter?.forEach((id) => {
      columns[id] = table?.fields?.[id];
    });
    columns = { ...table?.fields };
    filterFields &&
      Object.entries(filterFields).map((entry: any) => {
        const id = entry[0];
        const hide = entry[1].hide;
        const width = entry[1].width;
        columns[id] = {
          ...columns[id],
          metaData: { ...columns[id]?.metaData, hide: hide, width: width },
        };
      });

    columns = await getHeaders(
      payload?.dbId,
      payload?.tableId,
      columns,
      { getState },
      fieldArrayInFilter
    );
    const dataa = {
      columns: columns,
      row: rows,
      tableId: payload?.tableId,
      dbId: payload?.dbId,
      pageNo: page,
      // "isMoreData": !(querydata?.data?.data?.offset == null),
      filterId: payload?.filterId,
    };
    let getMoreData = offset && (page === 1 || (getState().table.pageNo + 1 === dataa.pageNo && getState().table.filterId === payload.filterId));
    dispatch(setTableLoading(false));
    if(getMoreData){
      dispatch(filterData({
        filterId : payload.filterId,
        tableId : payload.tableId,
        org_id : payload.org_id,
        pageNo : page+1 ,
        dbId : payload.dbId
      }))
    }else{
      dismissToast();
    }
    return dataa;
  }
);

export const deleteColumns = createAsyncThunk(
  "table/deleteColumns",
  async (
    payload: DeleteColumnsTypes,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    if (payload?.metaData?.isLookup == true) {
      const data = {
        viewFieldId: payload?.fieldName,
      };
      const newDatabase = await deleteFieldInView(
        payload?.dbId,
        payload?.tableId,
        data
      );
      dispatch(
        setAllTablesData({
          dbId: newDatabase?.data?.data?._id,
          tables: newDatabase?.data?.data?.tables,
          deletedTables: newDatabase?.data?.data?.deletedTables,
          orgId: newDatabase?.data?.data?.org_id,
        })
      );
    } else {
      const deletedfield = await deleteField(
        payload?.dbId,
        payload?.tableId,
        payload?.fieldName
      );
      dispatch(
        setAllTablesData({
          dbId: deletedfield?.data?.data?.data?._id,
          tables: deletedfield?.data?.data?.data?.tables,
          deletedTables: deletedfield?.data?.data?.data?.deletedTables,
          orgId: deletedfield?.data?.data?.data?.org_id,
        })
      );
    }
    const { tableId, dbId } = getState().table;
    if (payload?.filterId) {
      dispatch(
        filterData({
          filterId: payload?.filterId,
          tableId: payload?.tableId,
          dbId: payload?.dbId,
        })
      );
    } else {
      dispatch(bulkAddColumns({ tableName: tableId, dbId: dbId }));
    }
    return 2;
  }
);

export const updateColumnHeaders = createAsyncThunk(
  "table/updateColumnHeaders",
  async (payload: UpdateColumnHeaderTypes, { dispatch, getState }) => {
    const data = {
      filterId: payload?.filterId,
      newFieldName: payload?.label,
      newFieldType: payload?.fieldType,
      metaData: payload?.metaData,
      meta : {
        tableId : payload.tableName,
        fieldId : payload.columnId
      }
    };
    if (payload?.metaData?.isAllHide !== undefined) {
      const dbData = await hideAllField(payload?.dbId, payload?.tableName, {
        metaData: payload?.metaData,
        filterId: payload?.filterId,
      });
      dispatch(
        setAllTablesData({
          dbId: dbData?.data?.data?._id,
          tables: dbData?.data?.data?.tables,
          deletedTables: dbData?.data?.data?.deletedTables,
          orgId: dbData?.data?.data?.org_id,
        })
      );
      let fields = dbData?.data?.data?.tables?.[payload?.tableName]?.fields;
      if (payload?.filterId) {
          const filterFields = dbData?.data?.data?.tables?.[payload?.tableName]?.filters?.[payload?.filterId]?.fields;
          for (let fieldId in filterFields) {
                fields = {
                  ...fields, 
                  [fieldId]: {
                    ...fields[fieldId], 
                    metaData: {
                      ...fields[fieldId].metaData, 
                      hide: filterFields[fieldId]?.hide, 
                    },
                  },
                };
             }
      }
      const updatedColumns = await getHeaders(
        payload.dbId,
        payload.tableName,
        fields,
        { getState }
      );
      return updatedColumns;
    }
    //call api to update backend
    const updatedDbdata = await updateField(
      payload?.dbId,
      payload?.tableName,
      payload?.columnId,
      data
    );
    dispatch(
      setAllTablesData({
        dbId: updatedDbdata?.data?.data?._id,
        tables: updatedDbdata?.data?.data?.tables,
        deletedTables: updatedDbdata?.data?.data?.deletedTables,
        orgId: updatedDbdata?.data?.data?.org_id,
      })
    );
    if (payload?.metaData?.query) {
      dispatch(
        bulkAddColumns({ tableName: payload?.tableName, dbId: payload?.dbId })
      );
      return;
    }
    let updatedColumn =
      updatedDbdata?.data?.data?.tables?.[payload?.tableName]?.fields?.[
        payload?.columnId
      ];
    if (payload?.filterId) {
      var updatedFilterColumn =
        updatedDbdata?.data?.data?.tables?.[payload?.tableName]?.filters?.[
          payload?.filterId
        ]?.fields?.[payload?.columnId];
      const updatedMetaData = {
        ...updatedColumn.metaData, 
        hide: updatedFilterColumn.hide,
        width: updatedFilterColumn.width,
      };
      updatedColumn = {
        ...updatedColumn, 
        metaData: updatedMetaData, 
      };
    }
    updatedColumn = { [payload?.columnId]: updatedColumn };
    updatedColumn = await getHeaders(
      payload.dbId,
      payload.tableName,
      updatedColumn,
      { getState }
    );
    return updatedColumn[0];
  }
);

export const addColumnrightandleft = createAsyncThunk(
  "table/addColmunsrightandleft",
  async (
    payload: UpdateColumnHeaderTypes,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const data = {
      filterId: payload?.filterId,
      fieldName: payload?.fieldName,
      fieldType: payload?.fieldType,
      direction: payload?.direction,
      position: payload?.position,
      metaData: {hide: false, ...payload?.metaData},
      selectedFieldName: payload?.selectedFieldName,
      selectedTable: payload?.selectedTable,
      query: payload?.query,
      linkedForeignKey: payload?.linkedValueName,
      foreignKey: payload?.foreignKey,
      duplicateField: payload?.duplicateField,
    };
    let createdfield;
    if (payload?.fieldType == "lookup") {
      createdfield = await createView(payload?.dbId, payload?.tableId, data);
      dispatch(
        setAllTablesData({
          dbId: createdfield?.data?.data?._id,
          tables: createdfield?.data?.data?.tables,
          deletedTables: createdfield?.data?.data?.deletedTables,
          orgId: createdfield?.data?.data?.org_id,
        })
      );
    } else {
      createdfield = await createField(payload?.dbId, payload?.tableId, data);
      dispatch(
        setAllTablesData({
          dbId: createdfield?.data?.data?.data?._id,
          tables: createdfield?.data?.data?.data?.tables,
          deletedTables: createdfield?.data?.data?.data?.deletedTables,
          orgId: createdfield?.data?.data?.data?.org_id,
        })
      );
    }

    const { tableId, dbId } = getState().table;
    if (payload?.filterId) {
      dispatch(
        filterData({
          filterId: payload?.filterId,
          tableId: payload?.tableId,
          dbId: payload?.dbId,
        })
      );
    } else {
      dispatch(
        bulkAddColumns({
          tableName: tableId,
          dbId: dbId,
          fields: createdfield?.data?.data?.fields,
        })
      );
    }
    return payload;
  }
);

export const addColumsToLeft = createAsyncThunk(
  "table/addColumsToLeft",
  async (
    payload: UpdateColumnHeaderTypes,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const data = {
      filterId: payload?.filterId,
      fieldName: payload?.fieldName,
      fieldType: payload?.fieldType,
      metaData: {hide : false, ...payload?.metaData},
      query: payload?.query,
      selectedFieldName: payload?.selectedFieldName,
      selectedTable: payload?.selectedTable,
      linkedForeignKey: payload?.linkedValueName,
      foreignKey: payload?.foreignKey,
      userQuery: payload?.userQuery,
    };
    let createdfield: any = {};
    if (payload?.fieldType == "lookup") {
      createdfield.data = await createView(
        payload?.dbId,
        payload?.tableId,
        data
      );
      dispatch(
        setAllTablesData({
          dbId: createdfield?.data?.data?.data?._id,
          tables: createdfield?.data?.data?.data?.tables,
          deletedTables: createdfield?.data?.data?.data?.deletedTables,
          orgId: createdfield?.data?.data?.data?.org_id,
        })
      );
    } else {
      createdfield = await createField(payload?.dbId, payload?.tableId, data);
      dispatch(
        setAllTablesData({
          dbId: createdfield?.data?.data?.data?._id,
          tables: createdfield?.data?.data?.data?.tables,
          deletedTables: createdfield?.data?.data?.data?.deletedTables,
          orgId: createdfield?.data?.data?.data?.org_id,
        })
      );
    }

    // dispatch(addColumnToLeft(payload));
    const { tableId, dbId } = getState().table;
    if (payload?.filterId) {
      dispatch(
        filterData({
          filterId: payload?.filterId,
          tableId: payload?.tableId,
          dbId: payload?.dbId,
        })
      );
    } else {
      dispatch(
        bulkAddColumns({
          tableName: tableId,
          dbId: dbId,
          fields: createdfield?.data?.data?.fields,
        })
      );
    }
    return payload;
  }
);

export const updateCells = createAsyncThunk(
  "table/updateCells",
  async (payload: UpdateColumnHeaderTypes, { getState }: { getState: any }) => {
    const { tableId, dbId } = getState().table;
    const {value, columnId, indexIdMapping, rowIndex, imageLink} = payload;
    const userJson = getOrgUsers(getState());
    if (payload?.dataTypes == "file") {
      const data = await uploadImage(
        dbId,
        tableId,
        rowIndex,
        columnId,
        value,
        imageLink,
        {rowIndex, indexIdMapping, columnId}
      );
      payload.newData = data?.data?.data;
      return payload;
    }
    let jsonToSend = {
      records: [
        {
          // where: `fld${tableId.substring(3)}autonumber = ${payload.rowIndex}`,
          where: `autonumber = ${payload.rowIndex}`,
          fields: { [columnId]: value },
        },
      ],
      meta : payload.indexIdMapping
    };
    if (payload?.updatedArray) {
      jsonToSend = { records: payload?.updatedArray, meta : payload?.indexIdMapping };
    }

    const data = await updateRow(dbId, tableId, jsonToSend);
    data.data.data.map((row) => {
      row["createdby"] = userJson?.[row["createdby"]]
        ? userJson?.[row["createdby"]]?.name
        : row["createdby"];
      row["updatedby"] = userJson?.[row["updatedby"]]
        ? userJson?.[row["updatedby"]]?.name 
        : row["updatedby"];
    });
    payload.newData = data?.data?.data;
    return payload;
  }
);
export const addRows = createAsyncThunk(
  "table/addRows",
  async (_, { getState }: { getState: any }) => {
    const userJson = getOrgUsers(getState());
    const { tableId, dbId } = getState().table;
    const newRow = await insertRow(dbId, tableId);
    // const createdby = "fld" + tableId.substring(3) + "createdby";
    // userInfo.forEach((obj) => {
    //   obj.users.forEach((user) => {
    //     if (user?.user_id?.id == newRow?.data?.data[0]?.["createdby"]) {
          newRow.data.data[0]["createdby"] =
            userJson[newRow.data.data[0]["createdby"]].name
      //     return;
      //   }
      // });
    // });
    return newRow?.data?.data[0];
  }
);
export const restoreDeletedRow = createAsyncThunk(
  "table/restoreDeletedRow",
  async (payload:any, { getState }: { getState: any }) => {
    const state = getState();
    const { tableId, dbId } = state.table;
    const users = state.dataBase.currentOrgUsers;
    let jsonToSend = {
      records: [
        {
          autonumber:payload.value.autonumber,
          updatedat:payload.value.updatedat,
        }
      ],
    };
    const newRow = await restoreRow(dbId, tableId,jsonToSend);
    let createdBy = newRow?.data?.data[0]?.["createdby"];
    newRow.data.data[0]["createdby"] = users[createdBy]?.name || createdBy;
    return newRow?.data?.data[0];
  }
);
export const addMultipleRows = createAsyncThunk(
  "table/addMultipleRows",
  async (payload:{rows: Array<any>, fromCSV:boolean}, {getState}:{getState:any}) => {
    const userJson = getOrgUsers(getState());
    const {tableId, dbId} = getState().table;
    let newRows:any;
    if(payload.fromCSV){
      newRows = await uploadCSV(dbId, tableId, payload.rows);
    }else{
      newRows = await insertMultipleRows(dbId, tableId, payload.rows);
    }
    //  userInfo.forEach((obj) => {
    //     obj.users.forEach((user) => {
    //       if (user?.user_id?.id == newRows?.data?.data[0]?.["createdby"]) {
            for(let i in newRows.data.data){
              newRows.data.data[i]["createdby"] =
                userJson[newRows.data.data[i]["createdby"]].name
            }
    //         return;
    //       }
    //     });
    //   });

    return newRows.data?.data;
  }
);
export const updateColumnsType = createAsyncThunk(
  "table/updateColumnsType",
  async (payload: TableDataType, { dispatch }) => {
    dispatch(updateColumnType(payload));
    return payload;
  }
);
export const deleteRows = createAsyncThunk(
  "table/deleteRows",
  async (
    payload: { deletedRowIndices: any; dataa: Array<any> , indicesRange : Array<Array<number>>},
    { getState }: { getState: any }
  ) => {
    const { tableId, dbId } = getState().table;
    await deleteRow(dbId, tableId, { 
      row_id: payload.deletedRowIndices, 
      meta : payload.indicesRange,
    });
    return payload.indicesRange;
  }
);
export const updateColumnOrder = createAsyncThunk(
  "table/updateColumnOrder",
  async (
    payload: {
      filterId: string;
      oldIndex: string;
      newIndex: string;
      id: string;
    },
    {dispatch , getState }: { dispatch:any ,getState: any }
  ) => {
    const data = {
      filterId: payload?.filterId,
      oldIndex: payload?.oldIndex,
      newIndex: payload?.newIndex,
    };
    const { tableId, dbId } = getState().table;
   const newData= await updateField(dbId, tableId, payload?.id, data);
    dispatch(
      setAllTablesData({
        dbId: newData?.data?.data?._id,
        tables: newData?.data?.data?.tables,
        deletedTables: newData?.data?.data?.deletedTables,
        orgId: newData?.data?.data?.org_id,
      })
    );
    return payload;
  }
);
export const updateMultiSelectOptions = createAsyncThunk(
  "table/updateMultiSelectOptions",
  async (payload: FilterDataTypes,{ dispatch }) => {
    const data = {
      newFieldName: payload?.label,
      newFieldType: payload?.fieldType,
      metaData: { option: payload?.metaData },
    };
    const newData= await updateField(
      payload?.dbId,
      payload?.tableName,
      payload?.fieldName,
      data
    );
    dispatch(
      setAllTablesData({
        dbId: newData?.data?.data?._id,
        tables: newData?.data?.data?.tables,
        deletedTables: newData?.data?.data?.deletedTables,
        orgId: newData?.data?.data?.org_id,
      })
    );
    
    return payload;
  }
);

export const getDeletedRows1 = createAsyncThunk (
  "table/getDeletedRows1", 
   async  (payload: any) =>{
      const {dbId, tableName} = payload;
      const data:any = await getDeletedRows(dbId, tableName,payload.pageNo);
      return data.data.data;
  }
)
export const getRowHistory1 = createAsyncThunk (
  "tables/rowHistory1", 
   async (payload:any) =>{
      const {dbId, tableName, autonumber,fieldId} = payload;
      const data = await getRowHistory(dbId, tableName, fieldId, autonumber,payload.pageNo );
      return data.data.data;
  }
)

export const changeFieldTypeThunk = createAsyncThunk (
  "table/changeFieldType",
  async(payload : {newFieldType : string, metaData : object, fieldId: string, orgId: string}, {getState, dispatch} : {getState : any, dispatch : any}) => {
    const state = getState();
    const {dbId, tableId} = state.table;
    const orgId = payload.orgId;
    const apiBody = {
      fieldType : payload.newFieldType, 
      metaData : payload.metaData
    }
    const resData = await changeFieldType(dbId, tableId, payload.fieldId, apiBody);
    dispatch(setAllTablesData({
      dbId,
      orgId,
      tables : resData.data.data.tables,
      deletedTables : resData.data.data.deletedTables
    }))
    dispatch(
      bulkAddColumns({
        tableName: tableId,
        dbId: dbId,
        fields: resData?.data?.data?.tables?.[tableId]?.fields,
      })
    );   
  }
)