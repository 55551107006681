import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import RowData from "./rowData"
import RowPopup from "../table/rowPopup/rowPopup";


function ClickableRowData(props) {
  const [clicked, setClicked] = useState(false);
  function onClick(){
    setClicked(true);
  }

  function onClose(){
    setClicked(false);
  }

  return (
    <>
      <Box onClick={onClick} sx={{cursor:"pointer"}}>
        <RowData {...props}/>
      </Box>
      {clicked && <RowPopup rowData={props.value} closePopup={onClose}/>}
    </>
  );
}

ClickableRowData.propTypes = {
  row: PropTypes.bool,
  heading: PropTypes.string,
  value : PropTypes.any,
  sx : PropTypes.object
};


export default ClickableRowData;
