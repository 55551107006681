import { Box, Modal, IconButton, TextField, Select, MenuItem, Checkbox, FormControl, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { customUseSelector } from "../../../store/customUseSelector";
import SelectFilePopup from "../../../table/selectFilepopup";
import './rowPopup.scss'
import LinkAutoCompletePopup from "../../linkAutoCompletePopup/linkAutoCompletePopup";
import { useParams } from "react-router-dom";
import FieldPopupModal from "../../../table/fieldPopupModal/fieldPopupModal";
import AddIcon from '@mui/icons-material/Add';
import { editCellsInBatch } from "../../../table/addRow";
import { useDispatch } from "react-redux";

export default function RowPopup(props) {
    const { rowData, closePopup } = props;
    const params = useParams();
    const dispatch = useDispatch();
    const [editableData, setEditableData] = useState(rowData);
    const allRows = customUseSelector((state) => state.table.data || []);
    const allFieldsofTable = customUseSelector((state) => state.table.columns || []);
    const [fieldsToShow, setFieldsToShow] = useState(allFieldsofTable || []);
    const [isAttachmentPopupOpen, setIsAttachmentPopupOpen] = useState(null);
    const users = customUseSelector((state) => state.dataBase.currentOrgUsers || {});
    const [linkAutoComplete, setLinkAutoComplete] = useState(null);

    const [directionAndId, setDirectionAndId] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [editField, setEditField] = useState(null);

    const rowIndex = allRows.findIndex(row => row.autonumber === editableData.autonumber);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        setEditableData(allRows[rowIndex])
    }, [allRows[rowIndex]])

    useEffect(() => {
        var newcolumn = [];
        allFieldsofTable.forEach((column) => {
            if (column?.metadata?.hide !== true) {
                newcolumn.push(column);
            }
        });
        setFieldsToShow(newcolumn);
    }, [allFieldsofTable]);

    const openPopper = (event) => {
        if(params?.templateId) return ;
        setAnchorEl({
            top : event.clientY, 
            left : event.clientX
        })
      }
      
    const closePopper = () => {
        setAnchorEl(null);
        setEditField(null);
      };

    const handleChange = (id) => (event) => {
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setEditableData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleBlur = (id, index, dataType) => () => {
        let currentUpdatedValue = editableData[id];
        if (dataType === 'user') {
            const selectedUser = users[currentUpdatedValue];
            currentUpdatedValue = `${selectedUser.name} (${selectedUser.email})`;
        }
        if (dataType === 'datetime') {
            const selectedDate = new Date(currentUpdatedValue);
            currentUpdatedValue = selectedDate;
        }
        const cell = [index, rowIndex];
        onSave(currentUpdatedValue, cell, dataType);
    };

    const handleAttachmentClick = (value, id, index) => {
        const d = value;
        setIsAttachmentPopupOpen({
            cell: [index, rowIndex],
            d,
            fieldId: id,
            rowAutonumber: editableData.autonumber
        });
    };

    const handleLinkautoComplete = (event, index) => {
        setLinkAutoComplete({
            cell: [index, rowIndex],
            fieldId: fieldsToShow?.[index]?.id,
            referencedFieldId: fieldsToShow?.[index]?.metadata?.foreignKey?.fieldId,
            rowAutonumber: allRows[rowIndex][`autonumber`],
            referencedTableName: fieldsToShow?.[index]?.metadata?.foreignKey?.tableId,
            anchorPosition: { x: event.pageX, y: event.pageY, width: 435, height: 35 }
        })
    }

    const handleClose = () => {
        setLinkAutoComplete(null)
    }

    const onSave = (val, cell, dataType) => {
        const dataStructureMapping = {
            singleselect: { data: { value: val } },
            user: { data: { value: val } },
            datetime: { data: { date: val } },
            multipleselect: { data: { tags: val } },
        };
        const list = [{ location: cell, value: dataStructureMapping[dataType] || { data: val } }];
        editCellsInBatch(list, dispatch, fieldsToShow, params, allRows, users);
    };

    const renderInputField = (id, key, value, columnType, metadata, index) => {
        let readOnly;
        metadata.isLookup === true ? (readOnly = true) : (readOnly = false);
    
        return (
            <Box 
                key={id}
                display="flex" 
                alignItems="center" 
                marginBottom={2} 
                className="row-popup-input-container"
            >
                <Box minWidth={150}  marginLeft={4}>
                    <InputLabel>{key}</InputLabel>
                </Box>
                
                <Box flexGrow={1}>
                    {(() => {
                        switch (columnType) {
                            case 'multipleselect':
                                return (
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            value={value || []}
                                            onChange={handleChange(id)}
                                            onBlur={handleBlur(id, index, columnType)}
                                            variant="outlined"
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {(metadata?.option || []).map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    <Checkbox checked={value?.includes(item.value)} />
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            case 'singleselect':
                                return (
                                    <FormControl fullWidth>
                                        <Select
                                            value={value || []}
                                            onChange={handleChange(id)}
                                            onBlur={handleBlur(id, index, columnType)}
                                            variant="outlined"
                                        >
                                            {(metadata?.option || []).map((val) => (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            case 'checkbox':
                                return (
                                    <Checkbox
                                        checked={Boolean(value)}
                                        onChange={handleChange(id)}
                                        onBlur={handleBlur(id, index, columnType)}
                                    />
                                );
                            case 'attachment':
                                return (
                                    <Box
                                        className="row-popup-attachment-container"
                                        onClick={() => handleAttachmentClick(value, id, index)}
                                    >
                                        {Array.isArray(value) && value.length > 0 ? (
                                            value.map((url, index) => (
                                                <Box
                                                    key={index}
                                                    component="img"
                                                    src={url}
                                                    alt={`Attachment ${index + 1}`}
                                                    className="row-popup-attachment-img"
                                                />
                                            ))
                                        ) : null}
                                    </Box>
                                );
                            case 'json':
                                return (
                                    <TextField
                                        value={typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                                        onChange={handleChange(id)}
                                        onBlur={handleBlur(id, index, columnType)}
                                        fullWidth
                                        multiline
                                        InputProps={{ readOnly }}
                                    />
                                );
                            case 'datetime':
                                return (
                                    <input
                                        type="date"
                                        value={value && !isNaN(new Date(value).getTime())  ? new Date(value).toISOString().split('T')[0] : ""}
                                        onChange={handleChange(id)}
                                        onBlur={handleBlur(id, index, columnType)}
                                    />
                                );
                            case 'user':
                                return (
                                    <FormControl fullWidth>
                                        <Select
                                            value={value || ""}
                                            onChange={handleChange(id)}
                                            onBlur={handleBlur(id, index, columnType)}
                                            variant="outlined"
                                        >
                                            {Object.values(users).map((user) => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {`${user.name} (${user.email})`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            case 'link':
                                return (
                                    <TextField
                                        value={value || ""}
                                        onClick={(event) => handleLinkautoComplete(event, index)}
                                        fullWidth
                                        InputProps={{ readOnly }}
                                    />
                                );
                            case 'numeric':
                            case 'phone':
                                return (
                                    <TextField
                                        value={value || ""}
                                        onChange={handleChange(id)}
                                        onBlur={handleBlur(id, index, columnType)}
                                        fullWidth
                                        inputProps={{ type: 'number', readOnly }}
                                    />
                                );
                            case 'email':
                            case 'singlelinetext':
                            case 'longtext':
                            case 'url':
                                return (
                                    <TextField
                                        value={value || ""}
                                        onChange={handleChange(id)}
                                        onBlur={handleBlur(id, index, columnType)}
                                        fullWidth
                                        inputProps={{
                                            readOnly,
                                        }}
                                    />
                                )
                            default:
                                return (
                                    <TextField
                                        value={value || ""}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{ readOnly }}
                                    />
                            );
                        }
                    })()}
                </Box>
            </Box>
        );
    };
    
    
    return (
        <>
            {isAttachmentPopupOpen && (
                <SelectFilePopup
                    title="uplaodfile"
                    label="UploadFileIcon"
                    attachment={isAttachmentPopupOpen}
                    open={isAttachmentPopupOpen ? true : false}
                    setOpen={setIsAttachmentPopupOpen}
                />
            )}

            {linkAutoComplete && (
                <LinkAutoCompletePopup
                    id={id}
                    open={linkAutoComplete ? true : false}
                    linkAutoComplete={linkAutoComplete}
                    handleClose={handleClose}
                />
            )}

            {open && (
                <FieldPopupModal
                    title= {editField ? 'edit column' : "create column"}
                    label="Column Name"
                    tableId={params?.tableName}
                    open={open}
                    setDirectionAndId={setDirectionAndId}
                    directionAndId={directionAndId}
                    anchorEl = {anchorEl}
                    closePopper={closePopper}
                    id={id}
                    editFieldId = {editField}
                />
            )}

            <Modal open={rowData} onClose={closePopup}>
                <Box className="row-popup">
                    <IconButton onClick={closePopup}
                        sx={{
                            position: "absolute",
                            top: 16,
                            right: 16,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className="row-data-heading-container">
                        <div className="row-data-heading">
                            Row Data
                        </div>
                    </div>

                    {fieldsToShow.map(({ id, title, dataType, metadata }, index) => {
                        let value = editableData[id];
                        return renderInputField(id, title, value, dataType, metadata, index);
                    })}

                    <Box mt={2} marginLeft={2} display="flex" justifyContent="center" alignItems="center">
                        <IconButton
                            aria-label="add"
                            size="large"
                            onClick={(event) => {openPopper(event)}}
                        >
                            <AddIcon />
                        </IconButton>
                        <span style={{ marginLeft: 8, fontSize: '16px', cursor: 'pointer' }}>
                            Add new field to this table
                        </span>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

RowPopup.propTypes = {
    closePopup: PropTypes.func,
    rowData: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};